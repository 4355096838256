export const theme = {
    colors:{
        Red:'#C51F2A',
        Red_2:"#9E0E18",
        Black:"#1d1d1d",
        Green:'#22C51F',
        Green_2:'#50A773',
        White:'#FFFFFF',
        Grey_1:'#F8F5F5',
        Grey_2:'#E6E3E3',
        Grey_3:'#C2BCBD',
        Grey_4:'#8F8585',
        Grey_5:'#2F2F2F',
        Grey_6: '#b3b0b8',
        Grey_7: '#dddada',
        blue:'#0B02C1',
        blue2:'#09046F'
    },

    text:{
        Primary:'#8F8585',
        Black_1:'#2F2F2F', 
        White_1:'#FFFFFF', 
        Red_1:'#C51F2A',
        Grey_1:'#C2BCBD',
        Yellow_1: '#B19A28',
        Yellow_2: '#F5E7A3',
        blue:'#0B02C1',
        blue2:'#09046F'
    },

    background:{
        Primary:'#F8F5F5',
        Red_1:'#C51F2A',
        White_2:'#FFFFFF',
        Grey_1:'#E6E3E3',
        Grey_3:'#Eeecec',
        Black_1:'#2F2F2F',
        Grey_2:'#C2BCBD',
        blue:'#0B02C1',
        blue2:'#09046F'

    },

}

