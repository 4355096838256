import styled from "styled-components";


export const Loading = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
`;