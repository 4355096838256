import styled from "styled-components";

export const BoxContainer = styled.div`
  width: 100%;
  height: 100vh;
  .row {
    justify-content: center;
    height: 100%;
    margin: 0;
    align-items: center;
    text-align: center;
  }
`;
